@import "reset";


// Fonts
@font-face {
  font-family: 'PP Neue Montreal Book';
  src: url('../assets/fonts/PPNeueMontreal-Book.woff2') format('woff2'),
      url('../assets/fonts/PPNeueMontreal-Book.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

// @font-face {
//   font-family: 'iA Writer Mono';
//   src: url('../assets/fonts/iAWriterMonoS-Regular.woff2') format('woff2'),
//       url('../assets/fonts/iAWriterMonoS-Regular.woff') format('woff');
//   font-weight: normal;
//   font-style: normal;
//   font-display: swap;
// }

// General & Colors

:root {
  --var-color-black: #030303;
  --var-color-grey: #F6F6F6;
  
  background-color: var(--var-color-black);

  color: var(--var-color-grey);
}

* {
  box-sizing: border-box;
}

body {
  font-family: "PP Neue Montreal Book", sans-serif;
  font-weight: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  font-size: 16px;

  margin: 12px;
}

h1, h2 {
  font-size: 24px;
}

h2 {
  opacity: 0.5;

  margin-bottom: 12px;
}

.position {
    margin-bottom: 12px;

}

.position--current {
  margin-bottom: 4px;
}

.position--prev {
  opacity: 0.5;
}

.links {
  display: flex;
  flex-direction: column;
}

.position a {
  color: var(--var-color-grey);
  text-decoration: none;
}

.links a {
  color: var(--var-color-grey);
  text-decoration: none;

  margin-bottom: 4px;

  opacity: 0.5;

  display: inline;

  width: 70px;
}

a:hover {
  text-decoration: underline;
}