html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  border: 0;
  margin: 0;
  padding: 0;
}

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after, q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

@font-face {
  font-family: PP Neue Montreal Book;
  src: url("PPNeueMontreal-Book.4e6ceecb.woff2") format("woff2"), url("PPNeueMontreal-Book.d19a9e33.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

:root {
  --var-color-black: #030303;
  --var-color-grey: #f6f6f6;
  background-color: var(--var-color-black);
  color: var(--var-color-grey);
}

* {
  box-sizing: border-box;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 12px;
  font-family: PP Neue Montreal Book, sans-serif;
  font-size: 16px;
  font-weight: normal;
}

h1, h2 {
  font-size: 24px;
}

h2 {
  opacity: .5;
  margin-bottom: 12px;
}

.position {
  margin-bottom: 12px;
}

.position--current {
  margin-bottom: 4px;
}

.position--prev {
  opacity: .5;
}

.links {
  flex-direction: column;
  display: flex;
}

.position a {
  color: var(--var-color-grey);
  text-decoration: none;
}

.links a {
  color: var(--var-color-grey);
  opacity: .5;
  width: 70px;
  margin-bottom: 4px;
  text-decoration: none;
  display: inline;
}

a:hover {
  text-decoration: underline;
}

/*# sourceMappingURL=index.ff462c20.css.map */
